@import "../scss/fonts";
@import "../scss/mixin";
@import "../scss/variables";
@import "~@ng-select/ng-select/themes/default.theme.css";
// Import styles

// If you want to add something do it here
@import "~ng-pick-datetime/assets/style/picker.min.css";

@import "custom";

body {
  margin: 0;

  &.modal-open {
    overflow: hidden;
  }
}

.row {
  @include row;
}

* {
  font-family: "Manrope", sans-serif;
  box-sizing: border-box;
}

i {
  font-size: 18px;

  @media screen and (min-width: $device-xl) {
    font-size: 22px;
  }
}

a {
  font-size: 14px;

  @media screen and (min-width: $device-xl) {
    font-size: 16px;
  }
}

.d-flex {
  @include flexbox;
}

.space-between {
  @include space-between;
}

.align-center {
  @include align-item-center;
}

.wrapper {
  @include flexbox;

  .form-data {
    width: 100%;
  }
}

main {
  width: calc(100% - 312px);
  margin-left: 312px;
  @media screen and (max-width: $device-xxl) {
    width: calc(100% - 275px);
    margin-left: 275px;
  }
}

form {
  input:focus ~ label,
  input:not(:placeholder-shown) ~ label,
  textarea:focus ~ label,
  textarea:not(:placeholder-shown) ~ label {
    top: -9px;
    left: 15px;
    font-size: 12px;
    opacity: 1;
    color: #2da5de;
    background: #fff;
  }
}

.form-group {
  position: relative;
  margin-bottom: 20px;
  width: 100%;

  .file-upload {
    .input-file-trigger {
      position: static;
    }
  }

  .select-date,
  .select-time {
    &::-webkit-calendar-picker-indicator {
      position: absolute;
      right: 16px;
      z-index: 10;
      opacity: 0;
      font-size: 24px;
    }
  }
  .icon-calendar {
    color: $primary;
  }
  .icon-clock {
    color: $primary;
    cursor: pointer;
    pointer-events: all;
  }
  @media only screen and (min-width: $device-xl) {
    margin-bottom: 30px;
  }

  i {
    pointer-events: none;
    position: absolute;
    top: 12px;
    right: 10px;

    @media only screen and (min-width: $device-xxl) {
      right: 20px;
    }

    &.icon-upload {
      color: $upload;
      cursor: pointer;
      pointer-events: all;
    }

    &.icon-arrow-down {
      color: $dropdown-arrow;
    }

    &.icon-delete {
      color: $delete;
      cursor: pointer;
    }
  }

  .floating-label {
    font-size: 14px;
    color: #cccccc;
    position: absolute;
    pointer-events: none;
    top: 14px;
    left: 15px;
    transition: all 0.5s ease;
    padding: 0 2px;
    z-index: 3;

    @media screen and (min-width: $device-xxl) {
      font-size: 16px;
      top: 12px;
    }
  }

  input[type="date"]:required:invalid::-webkit-datetime-edit {
    color: transparent;
  }

  input[type="date"]:focus::-webkit-datetime-edit {
    color: black !important;
  }

  input {
    max-width: 100%;
    width: 100%;
    height: 40px;
    @include border-radius(7px);
    border: 1px solid $border-color;
    padding: 0 15px;
    outline: 0;
    font-size: 16px;

    @media screen and (min-width: $device-xl) {
      height: 50px;
    }

    &:hover {
      background: $input-hover;
    }

    &:focus {
      border: solid 1px $primary;
      background: $white-color;
      caret-color: $secondary;
    }
  }

  textarea {
    width: 100%;
    @include border-radius(7px);
    border: 1px solid $border-color;
    padding: 5px 15px;
    outline: 0;
    font-size: 16px;

    &:hover {
      background: $input-hover;
    }

    &:focus {
      border: solid 1px $primary;
      background: $white-color;
      caret-color: $secondary;

      + i.icon-lock {
        color: $primary !important;
      }
    }
  }
  .absher-mobile {
    padding-left: 70px;
    + span {
      position: absolute;
      top: 12px;
      left: 15px;
      padding-right: 10px;
      border-right: 1px solid $border-color;
    }
  }

  .lbl-mobile {
    left: 72px;
  }

  .vat-ip {
    + span {
      position: absolute;
      top: 12px;
      right: 20px;
    }
  }
}

.btn-area {
  @include align-item-center;

  .form-group {
    margin-bottom: 0;

    .select-btn {
      width: 160px;
      @include border-radius(50px);
      background-color: $white-color;
      color: $primary;
      border-color: $primary;
      padding-left: 55px;
      cursor: pointer;
    }

    i {
      color: $primary;
      top: 50%;
      transform: translateY(-50%);
    }

    .icon-export {
      left: 20px;
    }
  }
}

.show-records {
  .form-group {
    max-width: 96px;
    width: 100%;
    margin-top: 10px;

    select {
      padding: 6px 20px;
    }

    i {
      right: 16px;
      top: 14px;
    }
  }
}

.search {
  max-width: 346px;
  margin-right: 10px;
  width: 100%;
}

.search,
.search-box {
  .icon-search {
    color: $subtitle;
  }
}

.breadcrum {
  color: $breadcrum;
  font-size: 14px;

  a {
    font-size: 14px;
    position: relative;
    padding-right: 22px;

    &::after {
      content: "\e902";
      font-family: "icomoon", sans-serif;
      display: inline-block;
      color: $sidebar-icon-color;
      font-size: 22px;
      font-weight: 400;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
    }
  }
  .breadcrum-text {
    &::after {
      content: "";
      display: none;
    }
  }
  span {
    font-size: 14px;
    position: relative;
    padding-right: 22px;

    &::after {
      content: "\e902";
      font-family: "icomoon", sans-serif;
      display: inline-block;
      color: $sidebar-icon-color;
      font-size: 22px;
      font-weight: 400;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
    }
  }
}

.search-box {
  max-width: 400px;
  width: 100%;
  position: relative;
  @include border-radius(7px);
  margin-right: 10px;

  .dropdown {
    margin: 0;
    position: absolute;
    max-width: 150px;

    .form-group {
      left: 0;
    }

    select {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      padding-right: 28px;

      option {
        font-weight: 500;
      }
    }

    i {
      right: 10px;
      top: 14px;
    }
  }

  .form-group {
    position: absolute;
    max-width: 250px;
    left: 150px;

    input {
      border-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.order-list {
  a {
    margin-right: 10px;
  }
  .pos-rel {
    datatable-body {
      datatable-body-row {
        .datatable-body-cell {
          line-height: inherit !important;
          font-size: 14px !important;
        }
      }
    }
  }
}

.login-form {
  .check {
    label {
      font-size: 16px;
      color: $checkbox-lable;
    }
  }
}

.check {
  position: relative;
  margin-bottom: 20px;

  @media screen and (min-width: $device-xl) {
    margin-bottom: 0;
  }

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;

    &:checked {
      + label {
        &:after {
          content: "\e904";
          font-family: "icomoon", sans-serif !important;
          display: block;
          position: absolute;
          top: 0px;
          left: 0px;
          background: $button-hover;
          color: $white-color;
          width: 16px;
          height: 16px;
          border-radius: 5px;
          padding: 2px;
        }
      }
    }
  }

  label {
    &:before {
      content: "";
      -webkit-appearance: none;
      background-color: transparent;
      border: 1px solid $checkox-border;
      padding: 0px;
      @include border-radius(5px);
      width: 18px;
      height: 18px;
      display: inline-block;
      position: absolute;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 12px;
      left: 0;

      @media screen and (min-width: $device-xl) {
        position: relative;
      }
    }
    color: $checkbox-lable;
    font-size: 14px;
    height: 20px;
    cursor: pointer;
    display: block;
    padding-left: 30px;

    @media screen and (min-width: $device-xl) {
      font-size: 16px;
      display: flex;
      padding: 0;
    }
  }
}

.select-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: 0;

  & ~ .floating-label {
    top: -9px;
    left: 15px;
    font-size: 12px;
    opacity: 1;
    color: $data_site_text_color;
    background: #fff;
  }

  &:last-child {
    margin-right: 0px;
  }

  .select-dropdown__button {
    padding: 0 15px;
    height: 46px;
    outline: 0;
    font-size: 16px;
    border: 1px solid $border-color;
    cursor: pointer;
    width: 100%;
    text-align: left;
    background: $white-color;
    @include border-radius(7px);

    &:focus {
      outline: none;
    }
  }

  .select-dropdown__list {
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    max-height: 300px;
    overflow: auto;
    margin: 0;
    padding: 0;
    border: 1px solid #cecece;
    border-top: 0px solid transparent;
    list-style-type: none;
    opacity: 0;
    pointer-events: none;
    transform-origin: top left;
    transform: scale(1, 0);
    transition: all ease-in-out 0.3s;
    z-index: 2;
    margin-top: 5px;
    @include border-radius(7px);

    &.active {
      opacity: 1;
      pointer-events: auto;
      transform: scale(1, 1);
      box-shadow: 0px 0px 20px $shadow-color;
    }

    .select-dropdown__list-item {
      display: block;
      list-style-type: none;
      padding: 10px 15px;
      background: #fff;
      font-size: 16px;
      line-height: 20px;
      cursor: pointer;
      color: #616161;
      text-align: left;
      transition: all ease-in-out 0.3s;
      @include border-radius(0px);

      &:hover {
        background-color: $input-hover;
        color: $button-hover;
        transition: all ease-in-out 0.3s;
      }
    }
  }
}

input[type="date"]:required:invalid::-webkit-datetime-edit {
  color: transparent;
}

input[type="date"]:focus::-webkit-datetime-edit {
  color: black !important;
}

input[type="time"]:required:invalid::-webkit-datetime-edit {
  color: transparent;
}

input[type="time"]:focus::-webkit-datetime-edit {
  color: black !important;
}

.dropdown-upload {
  width: 84.24%;
}

.upload-form-group {
  max-width: 100%;
  .file-upload {
    max-width: 100%;
    position: relative;
    margin-right: 10px;

    i {
      z-index: 1;
      pointer-events: all;
    }

    &.selected {
      .input-file-trigger {
        span {
          color: $dropdown-arrow;
          font-size: 12px;
          top: -9px;
        }
      }
    }

    .input-file {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      padding: 14px 0;
      cursor: pointer;
      z-index: 1;
    }

    .input-file-trigger {
      display: block;
      width: 100%;
      height: 46px;
      border-radius: 7px;
      border: 1px solid #d6d6dc;
      padding: 0 15px;
      outline: 0;
      font-size: 16px;
      line-height: 42px;
      transition: all 0.4s;
      cursor: pointer;

      span {
        top: 12px;
        left: 38px;
        font-size: 16px;
        opacity: 1;
        color: #ccc;
        background: $white-color;
        position: absolute;
        line-height: normal;
        transition: all 0.5s ease;
        padding: 0 2px;
        pointer-events: none;
      }

      .file-return {
        height: 35px;
        line-height: 35px;
        position: absolute;
        top: 5px;
        left: 45px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
        right: 10px;
      }
      .file-name-upload {
        color: black;
      }
    }
  }
}

.file-upload {
  max-width: 90%;
  position: relative;

  @media only screen and (min-width: $device-xl) {
    max-width: 85%;
  }

  i {
    z-index: 1;
    pointer-events: all;
  }

  &.selected {
    .input-file-trigger {
      span {
        color: $dropdown-arrow;
        font-size: 12px;
        top: -9px;
      }
    }
  }

  .input-file {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    padding: 14px 0;
    cursor: pointer;
    z-index: 1;
  }

  .input-file-trigger {
    display: block;
    width: 100%;
    height: 46px;
    border-radius: 7px;
    border: 1px solid #d6d6dc;
    padding: 0 15px;
    outline: 0;
    font-size: 16px;
    line-height: 42px;
    transition: all 0.4s;
    cursor: pointer;

    span {
      top: 12px;
      left: 38px;
      font-size: 16px;
      opacity: 1;
      color: #ccc;
      background: $white-color;
      position: absolute;
      line-height: normal;
      transition: all 0.5s ease;
      padding: 0 2px;
      pointer-events: none;
    }

    .file-return {
      height: 35px;
      line-height: 35px;
      position: absolute;
      top: 5px;
      left: 45px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%;
      right: 50px;
    }
  }
}

.text-danger {
  color: $danger;
  font-size: 14px;
  padding-top: 2px;
}

.google-map {
  .map {
    height: 240px;
    width: 776px;
  }
}

.vertical-middle {
  vertical-align: middle;
}

/* Reset some default styling */
.gjs-mdl-dialog {
  margin-top: 85px;
}

.gjs-cv-canvas,
.gjs-pn-commands {
  width: 80%;
}

.gjs-pn-views-container,
.gjs-pn-views {
  width: 20%;
}

.gjs-pn-options {
  right: 20%;
}

.sidebar {
  min-height: 100vh;
  height: 100%;
  .nav-dropdown {
    &.open {
      .nav-dropdown-items {
        height: 100%;
      }
    }
  }
  .nav-dropdown-items {
    height: 0;
    overflow: hidden;
  }
}

.preview .gjs-off-prv {
  display: none !important;
}

.dropdown {
  .arrow-down {
    border: solid black;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 2px;
    transform: rotate(-45deg);
    vertical-align: middle;
    transition: all 0.3s ease;
    border-color: #fff;
    margin-left: 5px;
  }

  &.active {
    .arrow-down {
      transform: rotate(45deg);
    }

    .sub-dropdown {
      display: block;
      height: 100%;
    }
  }

  .sub-dropdown {
    padding: 0;
    display: none;
    list-style: none;
    height: 0;
    transition: all 3s ease;

    > li a .active {
      background-color: $primary;
    }
  }
}

// old datatable css
table.dataTable th,
table.dataTable td {
  white-space: nowrap;
}

table.dataTable th.wordwrap,
table.dataTable td.wordwrap {
  white-space: normal;
}

table.table-bordered.dataTable td:first-child,
table.table-bordered.dataTable th:first-child {
  border-left-width: 1px;
}

.ngx-datatable.material .empty-row {
  text-align: center;
}

.ngx-datatable.material .datatable-header .datatable-header-cell,
.ngx-datatable.material .datatable-footer {
  font-size: 14px;
  font-weight: bold;
  color: $breadcrum;
}

.show-select select {
  width: 75px;
  display: inline-block;
}

.ngx-datatable.material .datatable-header .datatable-header-cell,
.ngx-datatable.material
  .datatable-body
  .datatable-body-row
  .datatable-body-cell {
  padding: 8px 10px;
}

.ngx-datatable.material .datatable-header-cell,
.ngx-datatable.material .datatable-body-cell {
  border: 1px solid #c8ced3;
}

.ngx-datatable.material .datatable-footer .datatable-pager li.active a {
  background-color: $primary;
  color: #fff;
  font-weight: bold;
}

.ngx-datatable.scroll-horz .datatable-body {
  width: 100% !important;
}

.ngx-datatable .datatable-body .datatable-scroll {
  width: 100% !important;
}

.ngx-datatable.material .datatable-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 12px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.54);
}

.ngx-datatable.material .datatable-footer .page-count {
  line-height: 50px;
  height: 50px;
  padding: 0 1.2rem;
}

.ngx-datatable.material .datatable-footer .datatable-pager {
  margin: 0 10px;
}

.ngx-datatable.material .datatable-footer .datatable-pager li {
  vertical-align: middle;
}

.ngx-datatable.material .datatable-footer .datatable-pager li.disabled a {
  color: rgba(0, 0, 0, 0.26) !important;
  background-color: transparent !important;
}

.ngx-datatable.material .datatable-footer .datatable-pager a {
  height: 22px;
  min-width: 24px;
  line-height: 22px;
  padding: 0 6px;
  border-radius: 3px;
  margin: 6px 3px;
  text-align: center;
  color: rgba(0, 0, 0, 0.54);
  text-decoration: none;
  vertical-align: bottom;
}

.ngx-datatable.material .datatable-footer .datatable-pager a:hover {
  color: rgba(0, 0, 0, 0.75);
  background-color: rgba(158, 158, 158, 0.2);
}

.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-left,
.ngx-datatable.material .datatable-footer .datatable-pager .datatable-icon-skip,
.ngx-datatable.material
  .datatable-footer
  .datatable-pager
  .datatable-icon-right,
.ngx-datatable.material
  .datatable-footer
  .datatable-pager
  .datatable-icon-prev {
  font-size: 20px;
  line-height: 20px;
  padding: 0 3px;
}

.ngx-datatable {
  transform: none !important;
  -webkit-transform: none !important;
  overflow: visible !important;

  &.material {
    box-shadow: none;
    .datatable-header {
      border-bottom: none;
      .datatable-header-cell {
        padding: 15px 20px;
      }
    }

    .datatable-body {
      .datatable-body-row {
        .datatable-body-cell {
          @include flexbox;
          align-items: center;
          padding: 5px 20px;
          font-size: 14px;
        }
      }
    }
  }

  &.fixed-row {
    .datatable-scroll {
      .datatable-body-row {
        .datatable-body-cell {
          > div {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-weight: 600;
          }
        }

        .datatable-body-group-cell {
          > div {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  .sortable .sort-btn:before {
    font-family: data-table, sans-serif; /* Added sans-serif as a fallback font */
    content: "c";
  }

  .sortable .sort-btn.datatable-icon-down:before {
    content: "f";
  }

  .sortable .sort-btn.datatable-icon-up:before {
    content: "e";
  }

  .datatable-body {
    .datatable-row-wrapper {
      &:last-child {
        box-shadow: none;

        .datatable-body-row {
          border: 0;
        }
      }

      .datatable-body-row {
        border-bottom: solid 1px $border-color;
      }
    }
  }
}

.datatable-div {
  outline: none !important;
}

.ngx-datatable .datatable-header .datatable-row-center {
  background-color: #fafafa;
}

.sticky-dt-header {
  position: fixed;
  top: 55px;
  z-index: 99;
  width: 100%;
}

.view-pass {
  position: relative;

  input {
    padding-right: 30px;
  }

  i.fa {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    z-index: 4;
  }
}

// datetimepicker issue fixed
@media (min-width: 992px) {
  .sidebar-fixed .sidebar {
    height: calc(100vh - 55px) !important;
    top: 55px;
  }

  .header-fixed .app-header {
    top: 0;
    left: 0;
    right: 0;
  }
}

input::-ms-reveal,
input::-ms-clear {
  display: none !important;
}

// Form Design

.form-control-select {
  height: 50px;
  @include border-radius(7px);
  appearance: none;
  outline: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 40px;
}

datatable-header {
  margin-bottom: 15px;
  @include border-radius(7px);

  datatable-header-cell {
    border: 0 !important;
    background: $tab-background !important;
    padding: 10px 20px !important;

    .datatable-header-cell-wrapper {
      font-weight: normal;
    }
  }
}

datatable-body {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
  border-radius: 7px;

  datatable-body-row {
    .datatable-body-cell {
      border: 0 !important;
      height: 50px;
      line-height: 35px !important;
    }
  }
}

.datatable-pager {
  margin-top: 20px !important;

  ul {
    border: solid 1px $border-color !important;

    li {
      &.active {
        a {
          background-color: transparent !important;
          border: solid 1px $primary !important;
          color: rgba(0, 0, 0, 0.54) !important;
        }
      }

      &:last-child {
        a {
          border: 0;
        }
      }

      a {
        width: 40px;
        height: 40px !important;
        padding: 0 !important;
        line-height: 40px !important;
        display: flex !important;
        align-items: center;
        justify-content: center;
        margin: 0 !important;
        border-right: solid 1px $border-color;
        border-radius: 0 !important;
      }
    }
  }
}

.btn-disabled {
  cursor: not-allowed !important;
}

modal-container {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;

  h5 {
    margin-top: 0;
  }

  .modal-dialog {
    background: #fff;
    width: 100%;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 7px;
    overflow: hidden;
    max-width: 1548px;

    &.modal-md-xs {
      max-width: 400px;

      .modal-content {
        padding: 15px 15px;
      }
    }

    &.modal-md-sm {
      max-width: 450px;

      .modal-content {
        padding: 20px 20px;
      }
    }

    &.modal-md {
      max-width: 450px;
    }

    &.modal-ml {
      max-width: 600px;
    }
    &.modal-xmd {
      max-width: 1000px;
    }
    &.modal-xmd-overflow {
      max-width: 1000px;
      overflow: visible;
    }
    &.modal-md-overflow {
      max-width: 450px;
      overflow: visible;
    }

    &.modal-lg {
      max-width: 1200px;
      @media screen and (max-width: $device-xxl) {
        max-width: 1140px;
      }
    }

    &.modal-xl {
      max-width: 1340px;
    }

    &.modal-xxl {
      max-width: 1635px;
      @media screen and (max-width: $device-xxl) {
        max-width: 1140px;
      }
    }

    &.trip-modal-popup {
      max-width: 1480px;
      @media screen and (max-width: 1700px) {
        max-width: 1140px;
      }
    }
  }
}

.modal-content {
  padding: 20px 20px;
  box-shadow: none !important;

  .modal-header {
    // margin-bottom: 20px;
    margin-top: 20px;
    .modal-title {
      margin-bottom: 0px;
      color: $data_site_text_color;
      .modal-icon {
        font-size: 20px !important;
        color: $primary;
        margin-right: 5px;
      }
    }
  }

  .modal-body {
    @include border-radius(7px);
    max-height: 625px;
    overflow-y: auto;

    h3 {
      font-weight: 700;
    }
  }

  .close {
    position: absolute;
    right: 30px;
    top: 30px;
    width: 30px;
    height: 45px;
    border-radius: 50px;
    border: solid 1px transparent;
    background: transparent;

    i {
      color: $primary;
    }
    span {
      i {
        cursor: pointer;
        color: red;
      }
    }
  }
  .modal-footer {
    @include flexbox;
    // margin-top: 20px;
    padding-top: 5px;
    justify-content: flex-end;
    .btn {
      margin-left: 10px !important;
    }
    .btn-primary {
      @include bordered-btn;
      background: $white-color;
    }
    .btn-warning {
      @include btn-secondary;
      height: 50px;
      line-height: 50px;
      @include border-radius(30px);
    }
    .btn-small {
      @include btn-small;
      height: 40px;
      line-height: 16px !important;
      @include border-radius(30px);
    }
  }
}

.form-group input:focus + label,
// .form-group input:not(:placeholder-shown) + label,
input:autofill ~ label.floating-label,
input:-webkit-autofill ~ label.floating-label,
.form-group textarea:focus + .floating-label,
.form-group textarea:not(:placeholder-shown) + .floating-label,
.floatingLabel .floating-label {
  top: -9px;
  left: 15px;
  font-size: 12px;
  opacity: 1;
  color: #2da5de;
  background: #fff;
  position: absolute;
}

label[for="term"] {
  left: 0 !important;
}

.terms-error {
  padding-top: 12px;
}

.form-group input:autofill + .floating-label,
.form-group input:-webkit-autofill + .floating-label,
.form-group textarea:focus + .floating-label,
.form-group textarea:not(:placeholder-shown) + .floating-label,
.floatingLabel .floating-label {
  top: -9px;
  left: 15px;
  font-size: 12px;
  opacity: 1;
  color: #2da5de;
  background: #fff;
  position: absolute;
}

.icon-calendar {
  color: $primary;
  cursor: pointer !important;
  pointer-events: all !important;
}

.fa-toggle-on {
  color: $completed;

  &::before {
    font-size: 28px;
  }
}

.fa-pencil {
  color: $primary;

  &::before {
    font-size: 28px;
  }
}

.fa-toggle-off {
  &::before {
    font-size: 28px;
  }
}

.sort-active {
  .icons {
    a {
      margin-right: 20px;
    }
  }
}

.fa {
  font-family: FontAwesome, sans-serif; /* Use FontAwesome with a generic font fallback */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1;
}

.m-0 {
  margin: 0 !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.truck-number {
  width: 100%;
  margin-bottom: 30px;
  h4 {
    font-size: 14px;
    font-weight: 600;
    color: $data_site_text_color;
    margin: 0 !important;
    max-width: 108px;
    margin-right: 26px !important;
  }
  .form-group {
    margin: 0;
    .counter-box {
      position: relative;
      max-width: 146px;
      width: 100%;

      .counter-input {
        text-align: center;
      }

      a {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        border: none;

        &:hover {
          background: $button-hover;

          i {
            color: $white-color;
          }
        }
      }

      .btn-small {
        width: 30px !important;
        height: 30px !important;
        border-radius: 7px !important;

        i {
          font-size: 12px;
          top: 51%;
          left: 30%;
          transform: translateY(-50%);
        }

        .icon-Plus {
          color: $white-color;
        }
      }

      .btn-minus {
        left: 5px;
        background: #efefef;
      }

      .btn-plus {
        right: 5px;
        background: $primary;
      }
    }
  }
}

.counter {
  margin-left: -12px;
  margin-right: -12px;

  .lbl-counter {
    max-width: 121px;
  }

  .counter-box {
    padding: 0 12px;
    position: relative;

    .counter-input {
      text-align: center;
    }

    a {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      border: none;

      &:hover {
        background: $button-hover;

        i {
          color: $white-color;
        }
      }
    }

    .btn-small {
      width: 40px !important;
      height: 40px !important;
      border-radius: 7px !important;

      i {
        font-size: 16px;
        top: 51%;
        transform: translateY(-50%);
        left: 30%;
      }

      .icon-Plus {
        color: $white-color;
      }
    }

    .btn-minus {
      left: 17px;
      background: #efefef;
    }

    .btn-plus {
      right: 17px;
      background: $primary;
    }
  }
}

.badge {
  color: $dark-primary;
}

.search-group {
  input {
    padding-right: 50px;
  }
}

.border-box {
  border: 1px solid $border-default-color;
  @include border-radius(7px);
  .detail-area {
    padding: 0px 20px;
    .row {
      .d-col-3 {
        width: 290px;
      }
      .detail-data {
        // width: 290px;
        width: 270px;
        padding: 20px 0;
        @include flexbox;
        flex-direction: column;
        border-right: 1px solid $border-default-color;
        .title {
          @include font-detail(12px, 17px);
          color: $subtitle;
          font-weight: 500;
        }
        .detail {
          @include font-detail(14px, 20px);
          color: $data_site_text_color;
          font-weight: 600;
        }
      }
      .br-none {
        border-right: none;
      }
    }
  }

  .detail-area:not(:last-child) {
    border-bottom: 1px solid $border-default-color;
  }

  .invoice-box {
    @include flexbox;
    padding: 25px 20px 30px 20px;

    &:not(:first-child) {
      padding: 0 20px 20px;
    }
    .form-group {
      max-width: 280px;
      margin-bottom: 0;
      margin-right: 24px;
      .select-dropdown {
        select {
          border-color: $border-default-color;
          color: $data_site_text_color;
          font-weight: 500;
          height: 46px;
          cursor: pointer;
        }
      }

      .form-control-select {
        padding: 6px 15px;
      }

      .floating-label {
        color: $data_site_text_color;
      }
    }
    .btn-medium {
      border-color: $secondary;
      background-color: $secondary;
      color: $white-color;
      font-size: 14px;
      margin-left: 20px;
      @include flexbox;
      @include flexCenter();
      i {
        color: $white-color;
        margin-right: 10px;
        font-size: 18px;
      }
      &:hover {
        background-color: $button-hover;
        border-color: $dark-primary;
      }
    }

    .btn-sm {
      width: 170px;
    }
  }

  & + .form-group {
    width: 280px;
    margin-top: 30px;

    .floating-label {
      color: $data_site_text_color;
    }
  }
}

.icon-box,
.icon-delete-box {
  @include flexbox;
  @include flexCenter();
  height: 46px;
  width: 46px;
  @include border-radius(7px);
  &:hover {
    background-color: $dark-primary;
  }
}

.icon-delete-box {
  background-color: $white-color;
  i {
    color: red;
  }

  &:hover {
    i {
      color: $white-color;
    }
  }
}

.icon-box {
  background-color: $primary;
  color: $white-color;
  i {
    color: $white-color;
  }
  &:hover {
    background-color: $dark-primary;
  }
}
input:-webkit-autofill,
input:-webkit-autofill.focus,
input:-webkit-autofill:active,
input:-webkit-autofill:focus,
input:-webkit-autofill:hover {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  -webkit-box-shadow: 0 0 0 30px var(--input-background-color) inset !important;
  -webkit-transition: none;
  transition: none;
}

input:-moz-autofill,
input:-moz-autofill.focus,
input:-moz-autofill:active,
input:-moz-autofill:focus,
input:-moz-autofill:hover {
  -moz-box-shadow: 0 0 0 30px #fff inset !important;
  -moz-box-shadow: 0 0 0 30px var(--input-background-color) inset !important;
  -moz-transition: none;
  transition: none;
}

.icon-image {
  left: 10px;
  color: #f6a324;
}

.icon-pdf {
  left: 10px;
  color: red;
}

.icon-toggle-on,
.icon-toggle-off {
  font-size: 34px;
}

.just-end {
  @include just-end;
}

.registerPassword {
  align-items: center !important;
  font-size: 16px !important;
  color: $checkbox-lable !important;
}

.provider-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}
.flex-col {
  flex-direction: column;
}

.icon-lock {
  color: #d6d6dc;
}

.icon-lock,
.icon-unlock {
  cursor: pointer !important;
  pointer-events: all !important;
}

.icon-unlock {
  color: $secondary;
}

textarea {
  resize: none;
}

.check-disabled {
  cursor: not-allowed !important;
}
.arabic-input {
  text-align: right;
}

.whitespace-pre-wrap {
  white-space: pre-wrap;
}

#profileLogo {
  border-radius: 7px;
}
.form-control-calender {
  padding-right: 50px !important;
}
.display-provider,
.display-location {
  color: #2c72b8;
  font-weight: bold;
}

.defaultStatusColor {
  color: $black-color;
}

.transpoter-name {
  color: black;
  position: relative;
  font-size: 16px;
  font-weight: 700;
}

.orderDetailsBreadcrumb {
  color: $link-color !important;
}
.empty-row {
  padding: 10px 0;
  text-align: center;
  width: 100%;
  border: 1px solid #d6d6dc;
  border-radius: 7px;
}

.orderStatusTruncate {
  white-space: nowrap;
  overflow: hidden;
  max-width: 230px;
  text-overflow: ellipsis;
}

.orderListStatusTruncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.black {
  color: black;
}

.red {
  color: red;
}
/**
.arabic-letter {
  display: flex;
  column-gap: 5px;
  flex-direction: row-reverse;
}
**/

.cancel-order-comment {
  color: red;
}

.canceled {
  // padding: 10px 0px;
  width: 100%;
  @include flexbox;
  @include flexColumn;
  @include align-item-center;
  flex-direction: row;
  font-size: 14px;
  font-weight: 600;
  position: relative;
  color: red;
  max-width: 50%;
  .cancelled-title {
    color: black;
    margin-right: 5px;
    margin-left: 28px;
  }
}

.cancel-order-box {
  width: 100%;
  border: 1px solid $input-border-color;
  @include border-radius(7px);
  .cancel-order-title {
    .heading-left,
    .heading-right {
      font-size: 16px;
      line-height: 26px;
      font-weight: 700;
    }
    .heading-left {
      color: #fc2121;
    }
    .heading-right {
      color: var($data_site_text_color);
    }
  }
  .cancel-reason {
    margin: 0;
    color: var($data_site_text_color);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: left;
  }
}

.icon-close-round {
  @include flexbox;
  @include flexCenter();
  height: 20px;
  width: 20px;
  background-color: #fc2121;
  @include border-radius(15px);
  .icon-close {
    color: white;
    font-size: 14px;
    margin: 0;
  }
}

.bayan-note {
  padding: 10px 0px;
  width: 100%;
  @include flexbox;
  @include flexColumn;
  @include align-item-center;
  flex-direction: row;
  font-size: 14px;
  font-weight: 600;
  position: relative;
  color: #2da5de;
  .bayan-note-title {
    color: black;
    margin-right: 5px;
  }
}
.disabled-button {
  cursor: not-allowed !important;
  background-color: $light-grey;
  i {
    margin-right: 10px;
  }
}
.hide-form {
  display: none;
}
.w-full {
  width: 100% !important;
}
.orderId {
  color: $primary;
  text-decoration: underline;
}
.disabled-sync {
  cursor: not-allowed !important;
}

.wasl-off {
  color: $dark-primary !important;
  font-size: 34px;
  margin-right: 16px !important;
  width: 40px;
  height: 52px;
}
.wasl-on {
  color: $icon-default-color !important;
  font-size: 34px;
  margin-right: 16px !important;
  width: 40px;
  height: 52px;
}
.wasl {
  font-size: 34px;
  margin-right: 16px;
}

/* spinner.component.css */
.spinner {
  width: 60px;
  height: 60px;
  color: rgb(0, 172, 193);
  opacity: 0.5;
  position: fixed;
  z-index: 99999 !important;
}

.disabled {
  color: $subtitle !important;
  cursor: not-allowed !important;
}
.open-location {
  display: flex;
  flex-direction: column;
}

.character-truncate {
  display: inline-block;
  max-width: 100px;
}
.icon-arrow-down {
  pointer-events: none;
  position: absolute;
}

.folder-icon {
  cursor: pointer;
  font-size: 18px;
  color: $dark-primary;
}

///////// Custom Scrollbar Style//////////

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

.arrow-down-icon {
  .icon-arrow-down {
    top: 50%;
    right: 12px;
    margin-top: -10px;
  }
}

.seperator-mobile {
  color: $dark-primary;
  font-size: 22px;
}

/* General Alert Styles */

.alert-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  // margin: 10px;
  font-family: Arial, sans-serif;
  width: 100%;
}

.alert {
  padding: 15px;
  border-radius: 5px;
  color: #333;
  font-size: 14px;
}

.alert strong {
  font-weight: bold;
}

.alert.danger {
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  height: 92px;
  color: #721c24;
  display: flex;
  gap: 10px;
}

.reason-text {
  max-height: 60px;
  overflow: auto;
  max-width: 600px;
  font-weight: 200;
}

.cancellation-reason-text {
  max-height: 60px;
  overflow: auto;
  width: 100%;
  font-weight: 200;
}

.cancel-order-box {
  padding: 15px 15px;
}

.popup-btn-small {
  @include popup-btn-small;
  @include border-radius(30px);
}
.reason-title {
  margin-right: 4px;
}

.ngx-spinner-overlay {
  top: 0 !important;
  left: 310px !important;
  width: 100% !important;
  height: 100% !important;
  right: 0 !important;
  > div {
    left: 38% !important;
    @media screen and (min-width: $device-xxl) {
      left: 42% !important;
    }
  }
  @media screen and (max-width: $device-xxl) {
    left: 272px !important;
  }
}
.cancelled-reason {
  word-break: break-all;
}
.login-spinner {
  .ngx-spinner-overlay {
    position: fixed;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
    height: 100% !important;

    > div {
      left: 0;
    }
  }
}
.map-modal-open {
  padding-top: 10px !important;
}

/* For highlighting today's date in datepickers */
.highlight-today {
  background-color: #e9edf0 !important; /* Light grey background */
  color: #000 !important; /* Black text for contrast */
  border-radius: 50%; /* Circular highlight */
}
/* For highlighting today's date in dashboard datepicker */
.available.today {
  background-color: #dde2e4 !important; /* Light grey background */
  color: #000; /* Black text for contrast */
  border-radius: 0; /* Circular highlight */
}

/* Override highlight-today if the date is selected */
.selected.highlight-today {
  background-color: #5bc0de !important; /* Default selected background color */
  color: #fff !important; /* Default selected text color */
  border-radius: 50%; /* Retain circular highlight */
}
