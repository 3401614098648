@import "../scss/variables";
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap");

$font-body: "Manrope", sans-serif;

@mixin font-family($face, $weight) {
  font-family: $font-body;
  font-weight: $weight;
}

@mixin font-detail($font-size-value, $line-height-value) {
  font-size: $font-size-value;
  line-height: $line-height-value;
}

@mixin common-font-size {
  @include font-detail(16px, 24px);
  @media only screen and (min-width: $device-xl) {
    @include font-detail(18px, 27px);
  }
}

@mixin body-font {
  @include common-font-size;
  font-style: normal;
  letter-spacing: normal;
  color: $data_site_text_color;
}

@mixin font-xl {
  @include font-detail(20px, 35px);
  margin: 0;
  @media only screen and (min-width: $device-md) {
    @include font-detail(25px, 40px);
  }
  @media only screen and (min-width: $device-xl) {
    @include font-detail(30px, 45px);
  }
}

@mixin font-lg {
  @include font-detail(18px, 30px);
  margin: 0;
  @media only screen and (min-width: $device-md) {
    @include font-detail(20px, 32px);
  }
  @media only screen and (min-width: $device-xl) {
    @include font-detail(24px, 36px);
  }
}

@mixin font-md {
  @include font-detail(16px, 26px);
  margin: 0;
  @media only screen and (min-width: $device-md) {
    @include font-detail(18px, 28px);
  }
  @media only screen and (min-width: $device-xl) {
    @include font-detail(20px, 30px);
  }
}

@mixin font-sm {
  @include font-detail(14px, 22px);
  margin: 0;
  @media only screen and (min-width: $device-md) {
    @include font-detail(16px, 24px);
  }
}

@mixin font-xs {
  @include font-detail(12px, 18px);
  margin: 0;
  @media only screen and (min-width: $device-md) {
    @include font-detail(14px, 20px);
  }
}

@mixin font-xxs {
  @include font-detail(12px, 17px);
  margin: 0;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -o-border-radius: $radius;
  border-radius: $radius;
}

@mixin opacity($opacity) {
  -webkit-opacity: $opacity;
  -moz-opacity: $opacity;
  -o-opacity: $opacity;
  -ms-opacity: $opacity;
  opacity: $opacity;
}

@mixin transition($seconds, $timing: ease) {
  -webkit-transition: all $seconds ease;
  -moz-transition: all $seconds ease;
  -ms-transition: all $seconds ease;
  -o-transition: all $seconds ease;
  transition: all $seconds $timing;
}

@mixin transform($property) {
  -o-transform: $property;
  -webkit-transform: $property;
  -ms-transform: $property;
  -moz-transform: $property;
  transform: $property;
}

@mixin flexbox() {
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
}

@mixin flexCenter() {
  @include flexbox;
  @include align-item-center;
  @include justifyCenter;
}

@mixin centerData() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin text-shadow($x-axis, $y-axis, $blur, $color) {
  -ms-text-shadow: $x-axis $y-axis $blur $color;
  -o-text-shadow: $x-axis $y-axis $blur $color;
  -webkit-text-shadow: $x-axis $y-axis $blur $color;
  -moz-text-shadow: $x-axis $y-axis $blur $color;
  text-shadow: $x-axis $y-axis $blur $color;
}

@mixin box-shadow($x-axis, $y-axis, $blur, $color) {
  -ms-box-shadow: $x-axis $y-axis $blur $color;
  -o-box-shadow: $x-axis $y-axis $blur $color;
  -webkit-box-shadow: $x-axis $y-axis $blur $color;
  -moz-box-shadow: $x-axis $y-axis $blur $color;
  box-shadow: $x-axis $y-axis $blur $color;
}

@mixin box-shadow-none {
  -ms-box-shadow: none;
  -o-box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

@mixin box($property) {
  box-sizing: $property;
  -moz-box-sizing: $property;
  -webkit-box-sizing: $property;
  -o-box-sizing: $property;
}

@mixin text-clamp($line) {
  display: -webkit-box;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 100%;
}

@mixin space-none {
  margin: 0;
  padding: 0;
}

@mixin bg-cmn {
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
}

@mixin figure {
  margin: 0;
  display: inherit;
}

@mixin ul-disc-style {
  list-style: disc;
}

@mixin ol-decimal-style {
  list-style: decimal;
}

@mixin ol-circle-style {
  list-style: circle;
}

@mixin list-left-space {
  padding-left: 20px;
}

@mixin h1 {
  @include font-xl;
}

@mixin h2 {
  @include font-lg;
}

@mixin h3 {
  @include font-md;
}

@mixin h4 {
  @include font-sm;
}

@mixin h5 {
  @include font-xs;
}

@mixin h6 {
  @include font-xxs;
}

@mixin a {
  text-decoration: none;
}

@mixin p {
  font-size: 14px;
  line-height: 24px;

  @media screen and (min-width: $device-md) {
    font-size: 16px;
  }
}

@mixin row {
  @include flexbox;
  @include flexrow;
  @include flexwrap;
  margin: 0 -20px;
  @media only screen and (min-width: $device-xl) {
    margin: 0 -12px;
  }
  .d-col {
    @include flexbox;
    @include flexwrap;
    padding: 0 20px;
    width: 100%;
    @media only screen and (min-width: $device-lg) {
      padding: 0 12px;
    }
    &.d-col-2 {
      width: 100%;
      @media only screen and (min-width: $device-md) {
        width: 50%;
      }
    }
    &.d-col-3 {
      width: 100%;
      @media only screen and (min-width: $device-md) {
        width: 50%;
      }
      @media only screen and (min-width: $device-xl) {
        width: 33.33%;
      }
    }
    &.d-col-4 {
      width: 100%;
      @media only screen and (min-width: $device-md) {
        width: 50%;
      }
      @media only screen and (min-width: $device-lg) {
        width: 33.33%;
      }
      @media only screen and (min-width: $device-xl) {
        width: 25%;
      }
    }
    &.d-col-5 {
      width: 100%;
      @media only screen and (min-width: $device-md) {
        width: 50%;
      }
      @media only screen and (min-width: $device-lg) {
        width: 33.33%;
      }
      @media only screen and (min-width: $device-xl) {
        width: 20%;
      }
    }
    &.d-col-7 {
      width: 100%;
      @media only screen and (min-width: $device-md) {
        width: 50%;
      }
      @media only screen and (min-width: $device-lg) {
        width: 33.33%;
      }
      @media only screen and (min-width: $device-xl) {
        width: 16.65%;
      }
    }
    &.d-col-8 {
      width: 100%;
      @media only screen and (min-width: $device-md) {
        width: 50%;
      }
      @media only screen and (min-width: $device-lg) {
        width: 33.33%;
      }
      @media only screen and (min-width: $device-xl) {
        width: 13.8%;
      }
    }
  }
}

@mixin align-item-center {
  align-items: center;
}

@mixin align-item-end {
  align-items: flex-end;
}

@mixin align-item-start {
  align-items: flex-start;
}

@mixin just-center {
  justify-content: center;
}

@mixin just-end {
  justify-content: end;
}

@mixin just-start {
  justify-content: start;
}

@mixin space-between {
  justify-content: space-between;
}

@mixin flexrow {
  flex-direction: row;
}

@mixin flexColumn {
  flex-direction: column;
}

@mixin justifyCenter {
  justify-content: center;
}

@mixin flexwrap {
  flex-wrap: wrap;
}

@mixin textCenter {
  text-align: center;
}

@mixin app-none {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
}

@mixin button {
  display: block;
  @include font-family($font-body, 600);
  text-align: center;
  @include transition(0.3s);
  outline: none;
  cursor: pointer;
  @include box-shadow-none;
  text-decoration: none !important;
  position: relative;
  border: 1px solid $border-color;
  @include font-detail(16px, 19px);
}

@mixin btn-large {
  max-width: 515px;
  width: 100%;
  line-height: 16px !important;
  height: 40px;

  @media screen and (min-width: $device-xl) {
    height: 50px;
    line-height: 50px;
  }
}

@mixin btn-medium {
  max-width: 210px;
  width: 100%;
  line-height: 16px !important;
  height: 40px;

  @media screen and (min-width: $device-xl) {
    height: 50px;
    line-height: 50px;
  }
}

@mixin btn-small {
  max-width: 115px;
  width: 100%;
  line-height: 50px !important;
  height: 40px;
  margin: 0;

  @media screen and (min-width: $device-xl) {
    height: 50px;
    line-height: 50px;
  }
}

@mixin btn-primary {
  background-color: $primary;
  color: $white-color;
  &:hover {
    background-color: $button-hover;
  }
}

@mixin btn-secondary {
  background-color: $secondary;
  color: $white-color;
  border-color: var(--data_border_secondary_color);
  &:hover {
    background-color: $button-hover;
    color: $white-color;
  }
}

@mixin bordered-btn {
  background-color: transparent;
  color: $primary;
  border-color: $primary;
  &:hover {
    background-color: $button-hover;
    color: $white-color;
  }
}

@mixin hover-bordered-btn {
  background-color: $primary;
  color: $white-color;
  border-color: $primary;
  &:hover {
    background-color: transparent;
    color: $button-hover;
    border-color: $button-hover;
  }
}

@mixin close-bayan {
  background-color: transparent;
  color: $approval-required;
  border-color: $approval-required;
  &:hover {
    background-color: $approval-required;
    color: $white-color;
  }
}
@mixin popup-btn-small {
  max-width: 115px;
  line-height: 16px !important;
  width: 100%;
  height: 40px !important;
  margin: 0;
}
