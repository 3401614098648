@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@400;500;600;700&display=swap");

@font-face {
  font-family: "icomoon";
  src: url("../assets/fonts/icomoon.eot?9za9ja");
  src: url("../assets/fonts/icomoon.eot?9za9ja#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/icomoon.ttf?9za9ja") format("truetype"),
    url("../assets/fonts/icomoon.woff?9za9ja") format("woff"),
    url("../assets/fonts/icomoon.svg?9za9ja#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon", sans-serif !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-copy:before {
  content: "\e936";
}
.icon-support:before {
  content: "\e933";
}
.icon-settings:before {
  content: "\e934";
}
.icon-chat:before {
  content: "\e932";
}
.icon-send:before {
  content: "\e931";
}
.icon-ellipses:before {
  content: "\e930";
}
.icon-pdf:before {
  content: "\e92f";
}
.icon-unlock:before {
  content: "\e92e";
}
.icon-toggle-on:before {
  content: "\e92c";
  color: #0fb836;
}
.icon-toggle-off:before {
  content: "\e92d";
  color: #d6d6dc;
}
.icon-eye:before {
  content: "\e92b";
}
.icon-clock:before {
  content: "\e92a";
}
.icon-admin:before {
  content: "\e925";
}
.icon-Price-item:before {
  content: "\e926";
}
.icon-Price-Options:before {
  content: "\e927";
}
.icon-SP:before {
  content: "\e928";
}
.icon-SR:before {
  content: "\e929";
}
.icon-export:before {
  content: "\e924";
}
.icon-warning:before {
  content: "\e923";
}
.icon-search:before {
  content: "\e922";
}
.icon-Individual:before {
  content: "\e920";
}
.icon-Company:before {
  content: "\e921";
}
.icon-arrow-down:before {
  content: "\e901";
}
.icon-arrow-right:before {
  content: "\e902";
}
.icon-calendar:before {
  content: "\e903";
}
.icon-check:before {
  content: "\e904";
}
.icon-close:before {
  content: "\e905";
}
.icon-Contracts:before {
  content: "\e906";
}
.icon-dashboard:before {
  content: "\e907";
}
.icon-delete:before {
  content: "\e908";
}
.icon-Direct-Orders:before {
  content: "\e909";
}
.icon-download:before {
  content: "\e90a";
}
.icon-Drivers:before {
  content: "\e90b";
}
.icon-edit:before {
  content: "\e90c";
}
.icon-email:before {
  content: "\e90d";
}
.icon-globe:before {
  content: "\e90e";
}
.icon-image:before {
  content: "\e90f";
}
.icon-Invoice:before {
  content: "\e910";
}
.icon-lock:before {
  content: "\e911";
}
.icon-map:before {
  content: "\e912";
}
.icon-mark:before {
  content: "\e913";
}
.icon-message:before {
  content: "\e914";
}
.icon-minus:before {
  content: "\e915";
}
.icon-notification:before {
  content: "\e916";
}
.icon-Offers:before {
  content: "\e917";
}
.icon-Order-History:before {
  content: "\e918";
}
.icon-Plus:before {
  content: "\e919";
}
.icon-Special-Orders:before {
  content: "\e91a";
}
.icon-template:before {
  content: "\e91b";
}
.icon-truck:before {
  content: "\e91c";
}
.icon-Trucks:before {
  content: "\e91d";
}
.icon-upload:before {
  content: "\e91e";
}
.icon-view:before {
  content: "\e91f";
}
.icon-Waybill:before {
  content: "\e935";
}
.icon-waslSync:before {
  content: "\ea2e";
}
.icon-map-zoom:before {
  content: "\e900";
}
.icon-folder-open-solid:before {
  content: "\e937";
}
