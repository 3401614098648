$primary: #2da5de;
$dark-primary: #2c72b8;
$button-hover: #2c72b8;
$secondary: #f6a324;
$input-border-color: #d6d6dc;
$white-color: #ffffff;
$black-color: #000000;
$border-color: #d6d6dc;
$data_site_text_color: #11122c;
$link-color: #2c72b8;
$subtitle: #a0a0ac;
$checkox-border: #a0a0ac;
$input-hover: #f5fbfe;
$checkbox-lable: #696a81;
$tab-background: #f3f3f3;
$shadow-color: #11122c1a;
$icon-default-color: #d6d6dc;
$delete: #ee0000;
$upload: #46afe2;
$dropdown-arrow: #11122c;
$border-default-color: #d6d6dc;
$bell-icon-bg: #f6a324;
$chat-message-icon-bg: #f6a324;
$bell-notification: #fc2121;
$sidebar-icon-color: #a0a0ac;
$card-bg: #d6d6dc;
$dates-color: #4a4b68;
$start-color: #0fb836;
$breadcrum: #696a81;
$heading-color: #4a4b68;
$light-grey: #f3f3f380;
$location-color: #2c72b8;
$table-border: #e5e5e5;

$inprogress: #f6a324;
$cancelled: #fc2121;
$completed: #0fb836;
$ontheway: #0fbebe;
$delivered: #2c72b8;
$approval-required: #f17f21;
$req-delivered: #0fb836;
$sidebar-active: #172f4f;

$device-xs: 320px;
$device-sm: 576px;
$device-md: 768px;
$device-lg: 1024px;
$device-xl: 1200px;
$device-xxl: 1560px;
$danger: #ff5d62;
$corn-blue: #30a5de;
$permission-border: #e5e5e5;
